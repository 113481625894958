import React from 'react';
import SEO from '../components/SEO';
import StaticContent from '../components/CompanyProfile/StaticContent';

export default function ({}) {
  return (
    <>
      <SEO
        title={'Platform Summary'}
        description={
          'Online ‘new resident’ onboarding platform that assists soon-to-be new residents with their upcoming move by helping them effectively manage the entire process, step-by-step, in the most efficient and least stressful way possible. Benefits residents and onsite teams as well by automating incoming resident tracking, pre-move in compliance status, and document retrieval and management.'
        }
        brand={'mi'}
      />

      <StaticContent includeIntro />
    </>
  );
}
